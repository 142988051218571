/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={
        title === 'Home' ? 'Vulcan Ceres' : `%s | ${site.siteMetadata.title}`
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(meta)
        .concat({
          name: `keywords`,
          content: [
            'vulcan ai',
            'AI Based Agriculture Solutions',
            'AI Based fresh fruit bunches counting',
            'AI Based fresh fruit bunches grading',
            'AI Based palm fruit counting',
            'AI Based palm fruit grading',
            'AI Based fruit quality assessment',
            'AI Based palm fruit harvesting',
            'AI Based fruit counting and grading',
            'AI Based palm fruit counting',
            'Palm fruit harvesting',
            'Fruit counting and grading',
            'Fruit quality assessment',
            'Palm fruit counting',
            'Palm fruit grading',
            'Fresh fruit bunches counting',
            'Fresh fruit bunches grading',
            'FFB counting',
            'FFB grading',
            'sense',
            'vulcan sense+',
            'vulcan ceres',
            'replant',
            'vulcan replant',
            'vulcan replant+',
            'vulcan health',
            'vulcan health+',
            'smartgrader',
            'nutrient',
            'vulcan nutrient',
            'vulcan nutrient+',
            'trade',
            'vulcan trade',
            'vulcan trade+',
          ].join(`, `),
        })}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
