import React from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'gatsby';

export default function navItem({ route, display }) {
  return (
    <Link to={`/${route}`} className='link-no-style'>
      <Nav.Link as='span' eventKey={route}>
        {display}
      </Nav.Link>
    </Link>
  );
}
