import React from 'react';
import GetStarted from './getStarted';
import { Container, Row, Col } from 'react-bootstrap';
export default function footer({ hideGetStarted }) {
  return (
    <>
      {!hideGetStarted ? <GetStarted></GetStarted> : null}

      <Container fluid className='px-0'>
        <Row noGutters>
          <Col className='footer-col'>
            <footer className='bg-dark ' style={{ height: 50 }}>
              <span style={{ fontSize: '0.75rem' }}>
                Copyright &copy; {new Date().getFullYear()} Vulcan AI Pte. Ltd.
                All rights reserved.
              </span>
            </footer>
          </Col>
        </Row>
      </Container>
    </>
  );
}
